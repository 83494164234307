import React from 'react';
import { Button } from '~/components/buttons/Button';
import { alignCss } from '~/styles/alignCss';
import Image from 'next/image';
import * as styles from './index.styles';

export type Props = {
  onClickBack: () => void;
  title: string;
};

export const TESTID = {
  BACK: 'BACK',
} as const;

export const MESSAGE = {
  BACK: '戻る',
} as const;

export const ErrorPage: React.FC<Props> = (props: Props) => (
  <div>
    <div css={alignCss({ align: 'center' })}>
      <div css={styles.container}>
        <div css={styles.message}>{props.title}</div>
        <div css={styles.imageContainer}>
          <Image src={'/assets/logo/charactor-error.svg'} alt={''} width="122" height="115" />
        </div>
      </div>
    </div>

    <div css={alignCss({ align: 'center', mt: 4 })}>
      <Button fontSize="s" buttonHeight={32} onClick={props.onClickBack} testId={TESTID.BACK}>
        {MESSAGE.BACK}
      </Button>
    </div>
  </div>
);
