import { css } from '@emotion/react';
import { textStyle } from '~/styles/textStyle';
import { colors } from '~/styles/colors';
import { opacityStyle } from '~/styles/opacityStyle';
import { layout } from '~/styles/layout';

export const container = css``;

const height = 20;

export const message = css`
  ${textStyle('m', 'bold')}

  display: inline-block;
  color: ${colors.black};
  height: ${height}px;
  line-height: ${height}px;
  padding: ${layout.baseSize.margin}px ${layout.baseSize.margin * 3}px;
  border-radius: ${layout.baseSize.borderRadius}px;
  background-color: ${colors.main};
  margin-bottom: ${layout.baseSize.margin * 4}px;
`;

export const imageContainer = css`
  ${opacityStyle.translucent}
`;
